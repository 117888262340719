import React from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Box,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  HStack,
  Text,
  useDisclosure,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
} from "@chakra-ui/react";
import {
  HamburgerIcon,
  SettingsIcon,
  EditIcon,
  InfoIcon,
  CheckIcon,
  CloseIcon,
} from "@chakra-ui/icons";
import { ImExit, ImEnter } from "react-icons/im";
import useAuth from "../store/auth/authState";
import EditModal from "./dialogs/EditModal";
import useAppMode from "../store/appMode/appModeState";
import useI18n from "../hooks/useI18n";
import { useLogoutMutation } from "../modules/auth/hooks/useLogoutMutation";
import { FiLogOut } from "react-icons/fi";
import Settings from "../modules/accountAndSettings/components/Settings";
import Profile from "../modules/accountAndSettings/components/Profile";
import { useIsMobile } from "../hooks/useIsMobile";

interface HeaderProps {
  showEdit?: boolean;
  bgColor?: string;
}

const Header: React.FC<HeaderProps> = ({
  showEdit = true,
  bgColor = "white",
}) => {
  const navigate = useNavigate();
  const { tokens } = useAuth();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isEditMode, toggleEditMode } = useAppMode();
  const { t } = useI18n("auth");
  const { mutate: logout } = useLogoutMutation();
  const {
    isOpen: isSettingsOpen,
    onOpen: onSettingsOpen,
    onClose: onSettingsClose,
  } = useDisclosure();
  const {
    isOpen: isProfileOpen,
    onOpen: onProfileOpen,
    onClose: onProfileClose,
  } = useDisclosure();
  const { isMobile } = useIsMobile();

  const isAuthenticated = !!tokens.accessToken;

  const handleGoHome = () => {
    navigate("/home");
  };

  const handleProfileClick = () => {
    if (isAuthenticated && isMobile) {
      onProfileOpen();
    } else if (isAuthenticated) {
      navigate("/account/info");
    } else {
      onOpen();
    }
  };

  const handleSettingsClick = () => {
    if (isAuthenticated && isMobile) {
      onSettingsOpen();
    } else if (isAuthenticated) {
      navigate("/account/settings");
    } else {
      onOpen();
    }
  };

  const handleEditClick = () => {
    if (isAuthenticated) {
      toggleEditMode();
    } else {
      onOpen();
    }
  };

  const handleLogout = () => {
    logout();
    if (isEditMode) {
      toggleEditMode();
    }
  };

  const handleLogIn = () => {
    navigate("/auth/sign-in");
  };

  const currentBgColor = isEditMode ? "teal.500" : bgColor;
  const iconColor = isEditMode ? "white" : "gray.700";

  return (
    <>
      <Box
        h="30px"
        px={3}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        borderBottom="1px"
        borderColor="gray.200"
        backgroundColor={currentBgColor}
        position="relative"
        borderRadius={4}
      >
        {/* Left Side - Navigation Icons */}
        <HStack spacing={1}>
          <IconButton
            aria-label="Home"
            icon={<HamburgerIcon color={iconColor} />}
            size="s"
            variant="ghost"
            onClick={handleGoHome}
            _hover={{
              // COMMENT THIS IF YOU WANT TO REMOVE THE HOVER EFFECT
              boxShadow: isEditMode
                ? "0 0 8px 2px rgba(0, 255, 255, 0.6)"
                : "0",
              backgroundColor: "transparent", // New hover background color
              color: "white", // Change icon color on hover
            }}
          />
        </HStack>

        {/* Center - Guest Mode Text */}
        {!isAuthenticated && (
          <Text
            position="absolute"
            left="50%"
            transform="translateX(-50%)"
            fontSize="xs"
            color={isEditMode ? "white" : "gray.600"}
            whiteSpace="nowrap"
          >
            You are in guest mode. Please{" "}
            <Text as={Link} to="/auth/sign-in" color="teal.500">
              Log in
            </Text>
          </Text>
        )}

        {/* Right Side - Menu or Done Button */}
        {isEditMode ? (
          <IconButton
            aria-label="Complete Editing"
            icon={<CheckIcon color="white" />}
            size="s"
            variant="ghost"
            onClick={handleEditClick}
            _hover={{
              // COMMENT THIS IF YOU WANT TO REMOVE THE HOVER EFFECT
              boxShadow: "0 0 8px 2px rgba(0, 255, 255, 0.6)",
              backgroundColor: "transparent", // New hover background color
              color: "white", // Optional icon color change
            }}
          />
        ) : (
          <Menu>
            <MenuButton
              as={IconButton}
              icon={<SettingsIcon color={iconColor} />}
              variant="ghost"
              size="s"
            />
            <MenuList>
              {showEdit && (
                <MenuItem icon={<EditIcon />} onClick={handleEditClick}>
                  {t("edit_category")}
                </MenuItem>
              )}
              <MenuItem icon={<InfoIcon />} onClick={handleProfileClick}>
                {t("profile")}
              </MenuItem>
              <MenuItem icon={<SettingsIcon />} onClick={handleSettingsClick}>
                {t("settings")}
              </MenuItem>
              {isAuthenticated ? (
                <MenuItem icon={<ImExit />} onClick={handleLogout}>
                  {t("logout")}
                </MenuItem>
              ) : (
                <MenuItem icon={<ImEnter />} onClick={handleLogIn}>
                  {t("login")}
                </MenuItem>
              )}
            </MenuList>
          </Menu>
        )}
      </Box>

      {/* Modal for settings */}
      <Modal isOpen={isSettingsOpen} onClose={onSettingsClose} size="full">
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <Settings />
          </ModalBody>
          <ModalFooter>
            <Flex
              justifyContent="space-between"
              w="100%"
              px={8}
              mb={10}
              gap={10}
            >
              <Button
                leftIcon={<FiLogOut />}
                colorScheme="red"
                size="lg"
                onClick={handleLogout}
                px={8}
                alignItems="center"
              >
                {t("logout")}
              </Button>
              <Button
                rightIcon={<CloseIcon />}
                onClick={onSettingsClose}
                colorScheme="gray"
                size="lg"
                px={8}
                alignItems="center"
              >
                {t("close_button")}
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Modal for profile */}
      <Modal isOpen={isProfileOpen} onClose={onProfileOpen} size="full">
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <Profile />
          </ModalBody>
          <ModalFooter>
            <Flex
              justifyContent="space-between"
              w="100%"
              px={8}
              mb={10}
              gap={10}
            >
              <Button
                leftIcon={<FiLogOut />}
                colorScheme="red"
                size="lg"
                onClick={handleLogout}
                px={8}
                alignItems="center"
              >
                {t("logout")}
              </Button>
              <Button
                rightIcon={<CloseIcon />}
                onClick={onProfileClose}
                colorScheme="gray"
                size="lg"
                px={8}
                alignItems="center"
              >
                {t("close_button")}
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Edit Modal for unauthorized users */}
      <EditModal isOpen={isOpen} onClose={onClose} />
    </>
  );
};

export default Header;

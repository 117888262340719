import React, { useEffect } from "react";
import { Box, ChakraProvider, Flex } from "@chakra-ui/react";
import { BrowserRouter, useLocation } from "react-router-dom";
import "./App.css";
import theme from "./styles/theme";
import Router from "./modules/Router";
import AppQueryClient from "./AppQueryClient";
import store, { persistedStore } from "./store";
import { Provider as ReduxProvider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import Header from "./sharedComponents/Header";
import { useIsMobile } from "./hooks/useIsMobile";
import i18n from "./i18n/i18n";
import { HeaderProvider } from "./sharedComponents/HeaderContext";
import { useViewportHeight } from "./hooks/useViewPortHeight";
import { useSettings } from "./modules/accountAndSettings/hooks/useSettings";

const AppContent = () => {
  const { isMobile } = useIsMobile();
  const location = useLocation();
  const noHeaderRoutes = ["/auth", "/account"];

  // Apply the viewport height hook
  useViewportHeight();

  const shouldShowHeader = !noHeaderRoutes.some((route) =>
    location.pathname.startsWith(route),
  );

  const { settings } = useSettings();

  useEffect(() => {
    if (settings && settings.language) {
      i18n.changeLanguage(settings.language);
    }
  }, [settings]);

  return (
    <Flex
      direction="column"
      style={{ height: "calc(var(--vh, 1vh) * 100)" }} // Use dynamic height
    >
      {shouldShowHeader && (
        <Header
          showEdit={!isMobile && location.pathname !== "/home"}
          bgColor={isMobile ? "white" : undefined}
        />
      )}
      <Box as="main" flex="1" overflow="hidden">
        <Router />
      </Box>
    </Flex>
  );
};

function App() {
  return (
    <ChakraProvider theme={theme}>
      <ReduxProvider store={store}>
        <AppQueryClient>
          <PersistGate loading={null} persistor={persistedStore}>
            <BrowserRouter>
              <HeaderProvider>
                <AppContent />
              </HeaderProvider>
            </BrowserRouter>
          </PersistGate>
        </AppQueryClient>
      </ReduxProvider>
    </ChakraProvider>
  );
}

export default App;

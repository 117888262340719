import { logout } from "../services/auth.service";
import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { QUERY_KEY } from "../../../constants/constants";
import { useUser } from "./useUser";
import useAuth from "../../../store/auth/authState";

export const useLogoutMutation = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { clearTokens } = useAuth();

  const logOut = async () => {
    return logout();
  };

  return useMutation({
    mutationFn: logOut,
    onSuccess(response) {
      queryClient.setQueryData([QUERY_KEY.USER], null);
      clearTokens();
      navigate("/auth/sign-in");
    },
    onError: (error) => {
      console.error("Logout failed", error);
    },
  });
};

import { useQuery } from "@tanstack/react-query";
import { fetchProfile } from "../services/accountSettings.service";
import { Profile } from "../models/profile.model";

export const useGetProfileAPI = () => {
  return useQuery<Profile, Error>({
    queryKey: ["profile"],
    queryFn: fetchProfile,
  });
};

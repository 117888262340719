import React, {
  createContext,
  ReactNode,
  useContext,
  useState,
  useCallback,
} from "react";
import { useNavigate } from "react-router-dom";
import { Category } from "../modules/categories/models/category.model";

// Define the shape of the context value
interface HeaderContextType {
  fullCategoryHistory: Category[];
  parentCategoryHistory: Category[];
  addCategoryToHistory: (category: Category) => void;
  handleGoBack: () => void;
  handleGoHome: () => void;
  clearHistory: () => void;
  clearLastHistoryElement: () => void;
  getPreviousParentId: () => string | undefined;
  clearParentCategoriesHistory: () => void;
}

const HeaderContext = createContext<HeaderContextType | undefined>(undefined);

export const useHeader = () => {
  const context = useContext(HeaderContext);
  if (!context) {
    throw new Error("useHeader must be used within a HeaderProvider");
  }
  return context;
};

interface HeaderProviderProps {
  children: ReactNode;
}

export const HeaderProvider: React.FC<HeaderProviderProps> = ({ children }) => {
  const [fullCategoryHistory, setFullCategoryHistory] = useState<Category[]>(
    [],
  );
  const [parentCategoryHistory, setParentCategoryHistory] = useState<
    Category[]
  >([]);
  const navigate = useNavigate();

  const addCategoryToHistory = useCallback((category: Category) => {
    // Update parent history only if it's a parent category
    if (category.isCollection) {
      setParentCategoryHistory((prev) => {
        if (prev?.[prev.length - 1]?.id === category.id) return prev;
        return [...(prev || []), category];
      });
    }
    setFullCategoryHistory((prev) => {
      if (prev[prev.length - 1]?.id === category.id) return prev;
      return [...prev, category];
    });
  }, []);

  const handleGoBack = useCallback(() => {
    setParentCategoryHistory((prev) => {
      const newParentHistory = [...prev];
      newParentHistory.pop();
      return newParentHistory;
    });
  }, []);

  const handleGoHome = useCallback(() => {
    setFullCategoryHistory([]); // Clear full history
    setParentCategoryHistory([]); // Clear parent history
    navigate("/"); // Go to home page
  }, [navigate]);

  const getPreviousParentId = useCallback(() => {
    if (parentCategoryHistory && parentCategoryHistory.length > 1) {
      const previousParent =
        parentCategoryHistory[parentCategoryHistory.length - 2];
      return previousParent.id;
    }
  }, [parentCategoryHistory]);

  const clearHistory = useCallback(() => {
    setFullCategoryHistory([]);
  }, []);

  const clearLastHistoryElement = useCallback(() => {
    setFullCategoryHistory((prev) => {
      if (prev.length === 0) return prev;
      return prev.slice(0, -1);
    });
  }, []);

  const clearParentCategoriesHistory = useCallback(() => {
    setParentCategoryHistory(() => []);
  }, []);

  return (
    <HeaderContext.Provider
      value={{
        fullCategoryHistory,
        parentCategoryHistory,
        clearParentCategoriesHistory,
        addCategoryToHistory,
        handleGoBack,
        handleGoHome,
        getPreviousParentId,
        clearHistory,
        clearLastHistoryElement,
      }}
    >
      {children}
    </HeaderContext.Provider>
  );
};

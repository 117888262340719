import * as React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { PropsWithChildren, Suspense } from "react";
import CenterSpinner from "../sharedComponents/CenterSpinner";
import { useIsRestoring } from "@tanstack/react-query";
import useAuth from "../store/auth/authState";
import { useIsMobile } from "../hooks/useIsMobile";

const LazyComponent = (Component: React.LazyExoticComponent<any>) => {
  return (
    <Suspense fallback={<CenterSpinner />}>
      <Component />
    </Suspense>
  );
};

const GuardedRoute = ({ children }: PropsWithChildren) => {
  const { tokens } = useAuth();
  const isRestoring = useIsRestoring();
  if (isRestoring) return <CenterSpinner />;
  if (!tokens.accessToken) return <Navigate to="/auth/sign-in" replace />;
  return <>{children}</>;
};

const Router = () => {
  const { isMobile } = useIsMobile();

  const Login = React.lazy(() => import("./auth/components/Login.page"));
  const SignUp = React.lazy(() => import("./auth/components/SignUp.page"));
  const ResetPassword = React.lazy(
    () => import("./auth/components/ResetPassword.page"),
  );
  const VerifyEmail = React.lazy(
    () => import("./auth/components/VerifyEmail.page"),
  );
  const AccountSettingsPage = React.lazy(
    () => import("./accountAndSettings/components/AccountSettingsPage"),
  );
  const Profile = React.lazy(
    () => import("./accountAndSettings/components/Profile"),
  );
  const Billing = React.lazy(
    () => import("./accountAndSettings/components/Billing"),
  );
  const Settings = React.lazy(
    () => import("./accountAndSettings/components/Settings"),
  );
  const CategoriesPage = React.lazy(
    () => import("./categories/components/desktop-layout/CategoriesGrid.page"),
  );
  const MobileCategoriesPage = React.lazy(
    () =>
      import("./categories/components/mobile-layout/MobileCategoriesGrid.page"),
  );
  const HomePage = React.lazy(() => import("./home/components/Home.page"));
  const KeyboardPage = React.lazy(
    () => import("./keyboard/components/Keyboard.page"),
  );

  return (
    <Routes>
      <Route path="/auth/sign-in" element={LazyComponent(Login)} />
      <Route path="/auth/sign-up" element={LazyComponent(SignUp)} />
      <Route
        path="/auth/reset-password"
        element={LazyComponent(ResetPassword)}
      />
      <Route path="/auth/verify-email" element={LazyComponent(VerifyEmail)} />
      <Route
        path="/account/*"
        element={
          <GuardedRoute>{LazyComponent(AccountSettingsPage)}</GuardedRoute>
        }
      >
        <Route path="info" element={LazyComponent(Profile)} />
        <Route path="billing" element={LazyComponent(Billing)} />
        <Route path="settings" element={LazyComponent(Settings)} />
      </Route>
      <Route
        path="/categories"
        element={LazyComponent(
          isMobile ? MobileCategoriesPage : CategoriesPage,
        )}
      />
      <Route path="/keyboard" element={LazyComponent(KeyboardPage)} />
      <Route
        path="/"
        element={LazyComponent(
          isMobile ? MobileCategoriesPage : CategoriesPage,
        )}
      />
      <Route path="home" element={LazyComponent(HomePage)} />
      {/* Uncomment if contact page is needed */}
      {/* <Route path="/contactUs" element={LazyComponent(ContactPage)} /> */}
    </Routes>
  );
};

export default Router;
